<template>
  <div class="single-sys-common single-sys-user">
    <div class="mb-2">
      <el-button class="btn-primary" @click="goto()">新增</el-button>
    </div>
    <div class="table-wrapper">
      <c-table
          :index="false"
          :data="pageData"
          :tableColumns="tableColumns"
          rowKey="id"
          v-loading="pageLoading"
          :stripe="false"
      >
        <template v-slot:icon="{ row }">
          <i :class="row.icon" style="font-size: 20px"></i>
        </template>
        <template v-slot:custom="{ row }">
          <span class="opt-edit mr-6" @click="goto(row)">编辑</span>
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
        </template>
      </c-table>
    </div>
    <el-dialog
        custom-class="custom-dialog"
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogFormVisible"
        :before-close="handleDialogClose"
        width="1000px"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="120px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="上级菜单" prop="parentId">
                <el-select v-model="form.model.parentId" placeholder="请选择">
                  <el-option label="root" :value="0"></el-option>
                  <el-option v-for="(parent,p) in pageData" :key="p" :label="parent.name" :value="parent.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="菜单名称" prop="name">
                <el-input v-model="form.model.name"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型" prop="type">
                <el-select v-model="form.model.type" placeholder="请选择类型">
                  <el-option label="目录" :value="0"></el-option>
                  <el-option label="菜单" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="桥梁" prop="bridgeIdList">
                <el-select multiple collapse-tags v-model="form.model.bridgeIdList">
                  <el-option
                      v-for="item in bridgeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="url" prop="url">
                <el-input v-model="form.model.url"/>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="路由" prop="route">-->
<!--                <el-input v-model="form.model.route"/>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="12">
              <el-form-item label="排序" prop="sort">
                <el-input type="number" v-model="form.model.sort"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="图标" prop="icon">
                <el-input v-model="form.model.icon"/>
              </el-form-item>
            </el-col>
<!--            <el-col :span="12">-->
<!--              <el-form-item label="图标" prop="icon">-->
<!--                <i :class="form.model.icon" class="z-icon-form" v-if="hasIcon()"></i>-->
<!--                <el-button type="primary" size="small" plain @click="dialog.visible = true">{{iconContent()}}</el-button>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button type="primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择图标" fullscreen :visible.sync="dialog.visible">
      <div class="z-icon-list">
          <span v-for="icon in dialog.iconList" :key="icon" @click="selectOneIcon(icon)">
            <i :class="icon"></i>
          </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import iconList from '../../../../../assets/js/el-icon'
  import MPage from "@/mixins/MPage.js";
  import Configs from "./config/index";
  let mPage = MPage({
    pagePath: "",
    pageDelPath: "/bridge/sys/menu/remove",
    pageDelsPath: "",
    treePath:"/bridge/sys/menu/tree",
    pageParams: {},
    pageLoading:true
  });
  export default {
    mixins: [mPage],
    data() {
      let { tableColumns } = Configs(this);
      return {
        tableColumns,
        pageData: [],
        title:'',
        dialogFormVisible:false,
        pageLoading:false,
        model:{
          parentId: "",
          name: "",
          url: "",
          // route: "",
          sort: "",
          icon: "",
          type:"",
          bridgeIdList:[]
        },
        dialog:{
          visible:false,
          iconList:iconList
        },
        bridgeList:[],
        form: {
          model:{},
          rules:{
            parentId:[
              { required: true, message: '请选择上级菜单', trigger: 'blur' }
            ],
            name:[
              { required: true, message: '请填写名称', trigger: 'blur' }
            ],
            type:[
              { required: true, message: '请选择类型', trigger: 'blur' }
            ],
            url:[
              { required: true, message: '请填写url', trigger: 'blur' }
            ],
            // route:[
            //   { required: true, message: '请填写路由', trigger: 'blur' }
            // ],
            sort:[
              { required: true, message: '请填写排序', trigger: 'blur' }
            ],
            bridgeIdList:[
              { required: true, message: '请选择桥梁', trigger: 'blur' }
            ],
          }
        },
      };
    },
    created() {},
    mounted() {},
    methods: {
      pageGet(){
        this.pageLoading = true
        this.$http.get('/bridge/sys/menu/tree').then(res => {
          if (res.success && res.data){
            this.pageData = res.data
          }
          this.pageLoading = false
        })
      },
      goto(data) {
        this.$http.get('/bridge/info/listAll').then(res => {
          if (res.success){
            this.bridgeList = res.data
            // this.getMenuTree()
            this.form.model = Object.assign({}, this.model);
            if (data && data.id) {
              this.title = "编辑菜单";
              // data.semesterId = Number(data.semesterId);
              // data.subjectId = Number(data.subjectId);
              this.getDetail(data.id)
              // this.form.model = Object.assign({}, data);
            } else {
              this.title = "新增菜单";
              this.dialogFormVisible = true;
            }
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      getDetail(id){
        this.$http.get('/bridge/sys/menu/detail',{params:{id:id}}).then(res => {
          if (res.success){
            this.form.model = Object.assign({}, res.data);
            this.dialogFormVisible = true;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      submit (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let api = ''
            if (this.form.model.id) {
              delete this.form.model.createTime
              api = '/bridge/sys/menu/update'
            } else {
              api = '/bridge/sys/menu/save'
            }
            this.$http.post(api,this.form.model).then(res => {
              if (res.success){
                this.dialogFormVisible = false
                this.pageGet()
              } else {
                this.$message.error(res.msg)
              }
            })
          }
        })
      },
      selectOneIcon (icon) {
        this.form.model.icon = icon;
        this.dialog.visible = false
      },
      hasIcon () {
        return this.form.model.icon !== undefined
      },
      iconContent () {
        return this.form.model.icon ? '重新选择' : '选择图标'
      },
      handleAfterFn() {
        this.pageGet();
        this.dialogFormVisible = false;
      },
      resetFields() {
        this.dialogFormVisible = false;
      },
      handleDialogClose() {
        this.resetFields();
      },
    },
  };
</script>
<style scoped>
  .z-menu-icon{
    font-size: 20px;
  }
  .z-icon-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .z-icon-list span{
    margin: 1px 2px;
    font-size: 30px;
    border: 1px solid #CCC;
  }
  .z-icon-list span i{
    font-size: 30px;
    padding: 30px;
  }
  .z-icon-list span i:hover{
    cursor: pointer;
    background-color: #EEE;
  }
  .z-icon-form{
    padding: 10px;
    font-size: 20px;
    border: 1px solid #CCC;
    margin-right: 10px;
  }
  .z-icon-in-table{
    font-size: 15px;
    margin-right: 10px;
  }
</style>

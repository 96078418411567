export default function Configs(that) {
  const tableColumns = [
    {
      label: "菜单名称",
      prop: "name",
      align: "left",
      show: true
    },
    {
      label: "url",
      prop: "url",
      align: "center",
      show: true
    },
    // {
    //   label: "路由",
    //   prop: "route",
    //   align: "center",
    //   show: true
    // },
    {
      label: "图标",
      prop: "icon",
      name:'icon',
      align: "center",
      show: true
    },
    {
      label: "排序",
      prop: "sort",
      align: "center",
      show: true
    },
    // {
    //   label: "创建时间",
    //   prop: "createTime",
    //   align: "center",
    //   show: true
    // },
    {
      label: "操作",
      name: 'custom',
      align: "center",
      show: true
    },
  ];
  return {
    tableColumns
  };
}
